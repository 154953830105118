

















































































import { notificationStore } from '@/stores/notification-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'notification-item': () => import('../notification/notification-item.vue'),
  },
})
export default class Notifications extends Vue {
  notificationStore = notificationStore
  @Prop() mobile!: boolean
  @Prop({ default: '' }) label!: string
  @Prop({ default: '32' }) size!: string

  tabs = null
}
