import { render, staticRenderFns } from "./notification.vue?vue&type=template&id=51daa546&scoped=true&"
import script from "./notification.vue?vue&type=script&lang=ts&"
export * from "./notification.vue?vue&type=script&lang=ts&"
import style0 from "./notification.vue?vue&type=style&index=0&id=51daa546&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51daa546",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBadge,VDivider,VImg,VMenu})
