import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Notification } from '@/models/notification-model'
import { Subject, timer } from 'rxjs'
import { takeUntil, takeWhile } from 'rxjs/operators'
import { walletStore } from './wallet-store'
export class NotificationStore {
  @observable allNotifications: Notification[] = []
  @observable notifications: Notification[] = []
  @observable filter: 'all' | 'community' | 'market' | 'ido' = 'all'

  private _unsubcrible = new Subject()

  constructor() {
    //
    timer(1000, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(() => {
        if (walletStore.isLogin) this.fetchNotifications()
      })
  }

  @asyncAction *fetchNotifications() {
    try {
      // const res = yield apiService.notifications.fetchNotifications()
      // if (res) {
      //   this.allNotifications = res
      //   this.notifications = res
      // }
    } catch (error) {
      // snackController.commonError(error)
    }
  }

  @action.bound changeFilter(value: 'all' | 'community' | 'market' | 'ido') {
    this.filter = value
    switch (value) {
      case 'all':
        this.notifications = this.allNotifications
        break
      case 'community':
        this.notifications = this.allNotifications.filter((item) => item.type === 'community')
        break
      case 'market':
        this.notifications = this.allNotifications.filter((item) => item.type === 'market')
        break
      case 'ido':
        this.notifications = this.allNotifications.filter((item) => item.type === 'ido')
        break
      default:
        this.notifications = this.allNotifications
        break
    }
  }

  @asyncAction *removeNotification(item: Notification) {
    try {
      this.allNotifications = this.allNotifications.filter((notification) => notification.id !== item.id)
      this.notifications = this.notifications.filter((notification) => notification.id !== item.id)
      yield apiService.notifications.removeNotification({ notificationId: item.id })
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *markRead() {
    this.allNotifications = this.allNotifications.map((item) => {
      return { ...item, status: 'read' }
    })
    yield apiService.notifications.markAllRead()
  }

  @computed get unreadCount() {
    const unread = this.allNotifications.filter((item) => item.status !== 'read')
    return unread.length
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }
}

export const notificationStore = new NotificationStore()
